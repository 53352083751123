import React from 'react'
import {graphql} from 'gatsby'
import {mapEdgesToNodes} from '../lib/helpers'
import ProductPreviewList from '../components/product-preview-list'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'

import {responsiveTitle1} from '../components/typography.module.css'

export const query = graphql`
  query ProductsPageQuery {
    products: allSanityProduct {
      edges {
        node  {
          id
          icon {
            ...SanityImage
            alt
          }
          color
          showOnFrontPage
          name
          teaserText
          excerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const ProductsPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const productNodes = data && data.products && mapEdgesToNodes(data.products)

  return (
    <Layout>
      <SEO title='Tjenester' />
      <Container>
        <h1 className={responsiveTitle1}>Tjenester</h1>
        <p>PSO Regnskap AS tilbyr alle tjenester innen regnskapsfaget samt tilhørende rådgivning.</p>
        <p>Våre medarbeidere er kontinuerlig opptatt av egen kompetanse og tilpasning til teknologisk utvikling.</p>
        {productNodes && productNodes.length > 0 && <ProductPreviewList nodes={productNodes} />}
      </Container>
    </Layout>
  )
}

export default ProductsPage
